import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

class Header extends Component {
  componentDidMount() {
    const searchBtn = document.getElementById("quik-search-btn");
    const searchPopup = document.querySelector(".dlab-quik-search");
    const closeBtn = document.getElementById("quik-search-remove");

    searchBtn.addEventListener("click", function () {
      searchPopup.style.display = "block";
      searchPopup.classList.add("On");
    });

    closeBtn.addEventListener("click", function () {
      searchPopup.style.display = "none";
      searchPopup.classList.remove("On");
    });

    // Sidebar open/close
    const btn = document.querySelector(".navicon");
    const nav = document.querySelector(".header-nav");

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener("click", toggleFunc);

    // Sidenav li open close
    const navUl = [].slice.call(
      document.querySelectorAll(".header-nav > ul > li")
    );
    for (let y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      const active = current.classList.contains("open");
      navUl.forEach((el) => el.classList.remove("open"));
      if (active) {
        current.classList.remove("open");
      } else {
        current.classList.add("open");
      }
    }
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This will create a smooth scrolling effect
    });
  };

  render() {
    return (
      <>
        <header className="site-header mo-left header">
          <div className="top-bar text-black">
            <div className="container">
              <div className="row d-flex justify-content-between">
                <div className="dlab-topbar-left">
                  <ul>
                    <li>
                      <i className="fa fa-phone" style={{ color: "white" }}></i>
                      <a className="mx-2" href="tel:+919904225550" style={{ color: "white" }}>
                        +91 99042 25550
                      </a>
                    </li>
                    <li>
                      <i className="ti-email" style={{ color: "white" }}></i>
                      <a className="mx-2" href="mailto:mkt@castomech.com" style={{ color: "white" }}>
                        mkt@castomech.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="dlab-topbar-right topbar-social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/CASTOMECH?mibextid=ZbWKwL"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="site-button-link facebook hover"
                      >
                        <i className="fa fa-facebook" style={{ color: "white" }}></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/castomechtechnology?igsh=MTQ5OWN2NXd2bWdjYw=="
                        target="_blank"
                        rel="noopener noreferrer"
                        className="site-button-link instagram hover"
                      >
                        <i className="fa fa-instagram" style={{ color: "white" }}></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/castomech-technology/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="site-button-link linkedin hover"
                      >
                        <i className="fa fa-linkedin" style={{ color: "white" }}></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Sticky innerZ={999} enabled={true}>
            <div className="sticky-header main-bar-wraper navbar-expand-lg">
              <div className="main-bar clearfix">
                <div className="container clearfix">
                  <div className="logo-header mostion">
                    <Link to={"/"} onClick={this.scrollToTop}>
                      <img
                        src={require("./../../images/CASTOMECHLOGO.png")}
                        alt=""
                      />
                    </Link>
                  </div>

                  <button
                    className="navbar-toggler navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  <div className="extra-nav">
                    <div className="extra-cell">
                      <button
                        id="quik-search-btn"
                        type="button"
                        className="site-button-link"
                      ></button>
                    </div>
                  </div>

                  <div className="dlab-quik-search ">
                    <form action="#">
                      <input
                        name="search"
                        type="text"
                        className="form-control"
                        placeholder="Type to search"
                      />
                      <span id="quik-search-remove">
                        <i className="ti-close"></i>
                      </span>
                    </form>
                  </div>

                  <div
                    className={`header-nav navbar-collapse collapse justify-content-center`}
                    id="navbarNavDropdown"
                  >
                    <div className="logo-header d-md-block d-lg-none">
                      <Link to={"/"} onClick={this.scrollToTop}>
                        <img
                          src={require("./../../images/CASTOMECHLOGO.png")}
                          alt=""
                        />
                      </Link>
                    </div>
                    <ul className="nav navbar-nav">
                      <li className="activeh as-mega-menu homedemo">
                        <Link to={"/"} onClick={this.scrollToTop}>Home</Link>
                      </li>
                      <li className="activeh as-mega-menu homedemo">
                        <Link to={"/about"} onClick={this.scrollToTop}>About</Link>
                      </li>

                      <li className="has-mega-menu">
                        <Link to={"#"}>Products<i className="fa fa-chevron-down"></i></Link>
                        <ul className="mega-menu">
                          <li>
                            <Link to={"#"}>Green Sand Reclamation System</Link>
                            <ul>
                              <li><Link to={"/intensivemixer"}>Intensive Mixer</Link></li>
                              <li><Link to={"/polygonalSiever"}>Polygonal Siever</Link></li>
                              <li><Link to={"/knockoutMachine"}>Knockout Machine</Link></li>
                              <li><Link to={"/vibroFeeder"}>Vibro Feeder</Link></li>
                              <li><Link to={"/overbandmagneticspaerator"}>Over Band Magnetic Separator</Link></li>
                              <li><Link to={"/sandcooler"}>Sand Cooler</Link></li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"#"}>No-bake Sand Reclamation System</Link>
                            <ul>
                              <li><Link to={"/continuousmixer"}>Continuous Mixer</Link></li>
                              <li><Link to={"/compactiontables"}>Compaction Tables</Link></li>
                              <li><Link to={"/pneumaticconveyorsystem"}>Pneumatic Conveyor System</Link></li>
                              <li><Link to={"/batchmixermachine"}>Batch Mixer Machine</Link></li>
                              <li><Link to={"/Lumpreducer"}>Lump Reducer</Link></li>
                              <li><Link to={"/rollover"}>Roll Over</Link></li>
                              <li><Link to={"/doublearmcontmixer"}>Double Arm Cont. Mixer</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      <li className="activeh as-mega-menu homedemo">
                        <Link to={"/contact"} onClick={this.scrollToTop}>Contact Us</Link>
                      </li>
                    </ul>
                    <div className="dlab-social-icon">
                      <ul>
                        <li>
                          <a className="site-button circle fa fa-facebook" href="https://www.facebook.com/CASTOMECH?mibextid=ZbWKwL"></a>
                        </li>
                       
                        <li>
                          <a className="site-button circle fa fa-linkedin"  href="https://www.linkedin.com/company/castomech-technology/"></a>
                        </li>
                        <li>
                          <a className="site-button circle fa fa-instagram"href="https://www.instagram.com/castomechtechnology?igsh=MTQ5OWN2NXd2bWdjYw=="></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    );
  }
}

export default Header;
