import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const bg = require("../../images/background/bg3.png");
// style="background-image:url(images/background/bg3.png); background-size: cover;"

const Footer4 = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <footer className="site-footer">
        <div
          className="footer-top"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xl-3 col-lg-4 col-sm-6 footer-col-4 ">
                <div className="widget" style={{ width: "100%" }}>
                  <Link to={"/"}>
                    <img
                      src={require("./../../images/CASTOMECHLOGO.png")}
                      alt=""
                    />
                  </Link>
                  <h5
                    className="footer-title text-white"
                    style={{ marginTop: "20px" }}
                  >
                    WE CAST YOUR GOAL
                  </h5>

                  <ul className="list-inline m-a0">
                    <li>
                      <a
                        href="https://www.facebook.com/CASTOMECH?mibextid=ZbWKwL"
                        className="site-button facebook circle "
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.linkedin.com/company/castomech-technology/"
                        className="site-button linkedin circle "
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/castomechtechnology?igsh=MTQ5OWN2NXd2bWdjYw=="
                        className="site-button instagram circle "
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4">
                <div className="widget widget_services border-0">
                  <h5 className="footer-title text-white">Useful Link</h5>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/intensivemixer">Intensive Mixer </Link>
                    </li>
                    <li>
                      <Link to="/polygonalSiever">Polygonal Siever</Link>
                    </li>
                    <li>
                      <Link to="/knockoutMachine">Knockout Machine</Link>
                    </li>
                    <li>
                      <Link to="/vibroFeeder">Vibro Feeder</Link>
                    </li>
                    <li>
                      <Link to="/overbandmagneticspaerator">
                        Over Band Magnetic Separator
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-7 col-xl-2 col-lg-3 col-sm-6 footer-col-4">
                <div className="widget widget_services border-0">
                  <h5 className="footer-title text-white">Useful Link</h5>
                  <ul>
                    <li>
                      <Link to="/sandcooler">Sand Cooler</Link>
                    </li>
                    <li>
                      <Link to="/continuousmixer">Continuous Mixer</Link>
                    </li>
                    <li>
                      <Link to="/compactiontables">Compaction Tables</Link>
                    </li>
                    <li>
                      <Link to="/pneumaticconveyorsystem">
                        Pneumatic Conveyor System
                      </Link>
                    </li>
                    <li>
                      <Link to="/batchmixermachine">Batch Mixer Machine</Link>
                    </li>
                    <li>
                      <Link to="/Lumpreducer">Lump Reducer</Link>
                    </li>
                    <li>
                      <Link to="/rollover">Roll Over</Link>
                    </li>
                    <li>
                      <Link to="/doublearmcontmixer">
                        Double Arm Cont. Mixer
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
                <div className="widget widget_getintuch">
                  <h5 className="footer-title text-white">Contact us</h5>
                  <ul>
                    <li>
                      <i className="ti-location-pin"></i>
                      <strong>address</strong> Survey No.1754, Nr Plasma
                      Induction (INDIA) Pvt Ltd Hajipur
                      Kalol,Dist-Gandhinagar-382721{" "}
                    </li>
                    <li>
                      <i className="ti-mobile"></i>
                      <strong>Phone: </strong>
                      <a href="tel:+919054005999">+91 90540 05999</a>,
                      <a href="tel:+919033304845">+91 90333 04845</a>
                    </li>

                    <li>
                      <i className="ti-email"></i>
                      <strong>Email: </strong>
                      <a href="mailto:mkt@castomech.com">mkt@castomech.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 text-left ">
                {" "}
                <span>Copyright © 2024  <a href="https://newstarinfotech.com/">NewStar Infotech</a></span>{" "}
              </div>
            
              <div className="col-md-6 col-sm-6 text-right ">
                <div className="widget-link ">
                  <ul>
                    <li>
                      <Link to="/about"> About</Link>
                    </li>

                    <li>
                      <Link to="/privacy-policy"> Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer4;
