import React, { Component } from "react";
import Header from "./header";
import Footer4 from "./footer4";
import ScrollToTop from "../element/scrollToTop";
import { Link } from "react-scroll";
var bnr3 = require("./../../images/banner/bnr3.webp");
const privacypolicy = () => {
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bnr3 + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">privacy-policy</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>privacy-policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block">
          <div className="section-full content-inner inner-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="title">Introduction</h4>
                  <p className="font-18">
                    These terms and conditions govern your use of this website;
                    by using this website, you accept these terms and conditions
                    in full. If you disagree with these terms and conditions or
                    any part of these terms and conditions, you must not use
                    this website.
                  </p>
                  <p className="font-18">
                    You must be at least 18 years of age to use this website. By
                    using this website (and by agreeing to these terms and
                    conditions) you warrant and represent that you are at least
                    18 years of age.
                  </p>
                  <p className="font-18">
                    This website uses cookies. By using this website and
                    agreeing to these terms and conditions, you consent to our
                    Castomech Technology LLP’s use of cookies in
                    accordance with the terms of Castomech Technology LLP’s (privacy policy / cookies policy).
                  </p>

                  <div className="dlab-divider bg-gray-dark"></div>
                  <h4 className="title">License to use website</h4>
                  <p className="font-18">
                    Unless otherwise stated, Castomech Technology LLP and/or its licensors own the intellectual property
                    rights in the website and material on the website. Subject
                    to the license below, all these intellectual property rights
                    are reserved.
                  </p>
                  <p className="font-18">
                    You may view, download for caching purposes only, and print
                    pages or OTHER CONTENT from the website for your own
                    personal use, subject to the restrictions set out below and
                    elsewhere in these terms and conditions.
                  </p>
                  <p className="font-18">You must not:</p>
                  <ul className="list-circle">
                    <li>
                      republish material from this website (including
                      republication on another website).
                    </li>
                    <li>
                      -sell, rent or sub-license material from the website.
                    </li>
                    <li>show any material from the website in public.</li>
                    <li>
                      reproduce, duplicate, copy or otherwise exploit material
                      on this website for a commercial purpose.
                    </li>
                    <li>
                      edit or otherwise modify any material on the website.
                    </li>
                    <li>
                      redistribute material from this website except for content
                      specifically and expressly made available for
                      redistribution.
                    </li>
                  </ul>
                  <p className="font-18">
                    Where content is specifically made available for
                    redistribution, it may only be redistributed within your
                    organisation.
                  </p>
                  <div className="dlab-divider bg-gray-dark"></div>

                  <h4 className="title">Acceptable use</h4>
                  <p className="font-18">
                    You must not use this website in any way that causes, or may
                    cause, damage to the website or impairment of the
                    availability or accessibility of the website; or in any way
                    which is unlawful, illegal, fraudulent or harmful, or in
                    connection with any unlawful, illegal, fraudulent or harmful
                    purpose or activity.
                  </p>
                  <p className="font-18">
                    You must not use this website to copy, store, host,
                    transmit, send, use, publish or distribute any material
                    which consists of (or is linked to) any spyware, computer
                    virus, Trojan horse, worm, keystroke logger, rootkit or
                    other malicious computer software.
                  </p>
                  <p className="font-18">
                    You must not conduct any systematic or automated data
                    collection activities (including without limitation
                    scraping, data mining, data extraction and data harvesting)
                    on or in relation to this website without
                    [https://castomech.com] express written consent.
                  </p>
                  <p className="font-18">
                    You must not use this website to transmit or send
                    unsolicited commercial communications.
                  </p>
                  <p className="font-18">
                    You must not use this website for any purposes related to
                    marketing without https://castomech.com express
                    written consent.
                  </p>
                  <h4 className="title">Restricted access</h4>
                  <p className="font-18">
                    Access to certain areas of this website is restricted.
                    Castomech Technology LLP reserves the right
                    to restrict access to other areas of this website, or indeed
                    this entire website, at https://castomech.com
                    discretion.
                  </p>
                  <p className="font-18">
                    If Castomech Technology LLP provides you
                    with a user ID and password to enable you to access
                    restricted areas of this website or other content or
                    services, you must ensure that the user ID and password are
                    kept confidential.
                  </p>
                  <p className="font-18">
                    Castomech Technology LLP may disable your
                    user ID and password in www.castomech.com sole
                    discretion without notice or explanation.
                  </p>

                  <h4 className="title">Restricted access</h4>
                  <p className="font-18">
                    Access to certain areas of this website is restricted.
                    Castomech Technology LLP reserves the right
                    to restrict access to other areas of this website, or indeed
                    this entire website, at www.castomech.com discretion.
                  </p>
                  <p className="font-18">
                    If Castomech Technology LLP provides you
                    with a user ID and password to enable you to access
                    restricted areas of this website or other content or
                    services, you must ensure that the user ID and password are
                    kept confidential.
                  </p>
                  <p className="font-18">
                    Castomech Technology LLP may disable your
                    user ID and password in www.castomech.com sole
                    discretion without notice or explanation.
                  </p>

                  <h4 className="title">User content</h4>
                  <p className="font-18">
                    In these terms and conditions, “your user content” means
                    material (including without limitation text, images, audio
                    material, video material and audio-visual material) that you
                    submit to this website, for whatever purpose.
                  </p>
                  <p className="font-18">
                    You grant to Castomech Technology LLP a
                    worldwide, irrevocable, non-exclusive, royalty-free license
                    to use, reproduce, adapt, publish, translate and distribute
                    your user content in any existing or future media. You also
                    grant to Castomech Technology LLP the right
                    to sub-license these rights, and the right to bring an
                    action for infringement of these rights.
                  </p>
                  <p className="font-18">
                    Your user content must not be illegal or unlawful, must not
                    infringe any third party’s legal rights, and must not be
                    capable of giving rise to legal action whether against you
                    or Castomech Technology LLP or a third party
                    (in each case under any applicable law).
                  </p>
                  <p className="font-18">
                    You must not submit any user content to the website that is
                    or has ever been the subject of any threatened or actual
                    legal proceedings or other similar complaint.
                  </p>
                  <p className="font-18">
                    Castomech Technology LLP reserves the right
                    to edit or remove any material submitted to this website, or
                    stored on www.castomech.com servers, or hosted or
                    published upon this website.
                  </p>
                  <p className="font-18">
                    Notwithstanding https://castomech.com rights under
                    these terms and conditions in relation to user content,
                    Castomech Technology LLP does not undertake
                    to monitor the submission of such content to, or the
                    publication of such content on, this website.
                  </p>

                  <h4 className="title">No warranties</h4>
                  <p className="font-18">
                    This website is provided “as is” without any representations
                    or warranties, express or implied. Plasma Induction (India)
                    Private Limited makes no representations or warranties in
                    relation to this website or the information and materials
                    provided on this website.
                  </p>
                  <p className="font-18">
                    Without prejudice to the generality of the foregoing
                    paragraph, Castomech Technology LLP does not
                    warrant that:
                  </p>
                  <p className="font-18">
                    this website will be constantly available, or available at
                    all; or
                  </p>
                  <p className="font-18">
                    the information on this website is complete, true, accurate
                    or non-misleading.
                  </p>
                  <p className="font-18">
                    Nothing on this website constitutes, or is meant to
                    constitute, advice of any kind. If you require advice in
                    relation to any legal, financial or medical matter you
                    should consult an appropriate professional.
                  </p>

                  <h4 className="title">Limitations of liability</h4>
                  <p className="font-18">
                    Castomech Technology LLP will not be liable
                    to you (whether under the law of contact, the law of torts
                    or otherwise) in relation to the contents of, or use of, or
                    otherwise in connection with, this website:
                  </p>
                  <p className="font-18">
                    to the extent that the website is provided free-of-charge,
                    for any direct loss.
                  </p>
                  <p className="font-18">
                    for any indirect, special or consequential loss; or
                  </p>
                  <p className="font-18">
                    for any business losses, loss of revenue, income, profits or
                    anticipated savings, loss of contracts or business
                    relationships, loss of reputation or goodwill, or loss or
                    corruption of information or data.
                  </p>
                  <p className="font-18">
                    These limitations of liability apply even if Plasma
                    Induction (India) Private Limited has been expressly advised
                    of the potential loss.
                  </p>

                  <h4 className="title">Reasonableness</h4>
                  <p className="font-18">
                    By using this website, you agree that the exclusions and
                    limitations of liability set out in this website disclaimer
                    are reasonable.
                  </p>
                  <p className="font-18">
                    If you do not think they are reasonable, you must not use
                    this website.
                  </p>

                  <h4 className="title">Other parties</h4>
                  <p className="font-18">
                    You accept that, as a limited liability entity, Castomech Technology LLP has an interest in
                    limiting the personal liability of its officers and
                    employees. You agree that you will not bring any claim
                    personally against https://castomech.com officers or
                    employees in respect of any losses you suffer in connection
                    with the website.
                  </p>
                  <p className="font-18">
                    Without prejudice to the foregoing paragraph, you agree that
                    the limitations of warranties and liability set out in this
                    website disclaimer will protect https://castomech.com
                    officers, employees, agents, subsidiaries, successors,
                    assigns and sub-contractors as well as Castomech Technology LLP.
                  </p>

                  <h4 className="title">Unenforceable provisions</h4>
                  <p className="font-18">
                    If any provision of this website disclaimer is, or is found
                    to be, unenforceable under applicable law, that will not
                    affect the enforceability of the other provisions of this
                    website disclaimer.
                  </p>

                  <h4 className="title">Indemnity</h4>
                  <p className="font-18">
                    You hereby indemnify Castomech Technology LLP and undertake to keep Plasma Induction (India)
                    Private Limited indemnified against any losses, damages,
                    costs, liabilities and expenses including without limitation
                    legal expenses and any amounts paid by Plasma Induction
                    (India) Private Limited to a third party in settlement of a
                    claim or dispute on the advice of
                    https://castomech.com legal advisers incurred or
                    suffered by Castomech Technology LLP arising
                    out of any breach by you of any provision of these terms and
                    conditions, or arising out of any claim that you have
                    breached any provision of these terms and conditions.
                  </p>

                  <h4 className="title">
                    Breaches of these terms and conditions
                  </h4>
                  <p className="font-18">
                    Without prejudice to https://castomech.com other
                    rights under these terms and conditions, if you breach these
                    terms and conditions in any way, Castomech Technology LLP may take such action as Castomech Technology LLP deems appropriate to deal with the
                    breach, including suspending your access to the website,
                    prohibiting you from accessing the website, blocking
                    computers using your IP address from accessing the website,
                    contacting your internet service provider to request that
                    they block your access to the website and/or bringing court
                    proceedings against you.
                  </p>

                  <h4 className="title">Variation</h4>
                  <p className="font-18">
                    Castomech Technology LLP may revise these
                    terms and conditions from time-to-time. Revised terms and
                    conditions will apply to the use of this website from the
                    date of the publication of the revised terms and conditions
                    on this website. Please check this page regularly to ensure
                    you are familiar with the current version.
                  </p>

                  <h4 className="title">Assignment</h4>
                  <p className="font-18">
                    Castomech Technology LLP may transfer,
                    sub-contract or otherwise deal with
                    https://castomech.com rights and/or obligations under
                    these terms and conditions without notifying you or
                    obtaining your consent.
                  </p>
                  <p className="font-18">
                    You may not transfer, sub-contract or otherwise deal with
                    your rights and/or obligations under these terms and
                    conditions.
                  </p>

                  <h4 className="title">Severability</h4>
                  <p className="font-18">
                    If a provision of these terms and conditions is determined
                    by any court or other competent authority to be unlawful
                    and/or unenforceable, the other provisions will continue in
                    effect. If any unlawful and/or unenforceable provision would
                    be lawful or enforceable if part of it were deleted, that
                    part will be deemed to be deleted, and the rest of the
                    provision will continue in effect.
                  </p>

                  <h4 className="title">Entire agreement</h4>
                  <p className="font-18">
                    These terms and conditions, together with Documents,
                    constitute the entire agreement between you and Castomech Technology LLP in relation to your use of
                    this website, and supersede all previous agreements in
                    respect of your use of this website.
                  </p>

                  <h4 className="title">Law and jurisdiction</h4>
                  <p className="font-18">
                    These terms and conditions will be governed by and construed
                    in accordance with (GOVERNING LAW), and any disputes
                    relating to these terms and conditions will be subject to
                    the non-exclusive jurisdiction of the courts of Ahmedabad.
                  </p>

                  <h4 className="title">Registrations and authorisations</h4>
                  <p className="font-18">
                    Castomech Technology LLP is registered with
                    Castomech Technology LLP. You can find the
                    online version of the register at www.castomech.com
                  </p>
                  <p className="font-18">www.castomech.com details:</p>
                  <p className="font-18">
                    The full name of Castomech Technology LLP
                  </p>
                  <p className="font-18">
                    Castomech Technology LLP is registered in
                    Ahmedabad.
                  </p>

                  <h4 className="title">
                    Castomech Technology LLP
                  </h4>
                  <p className="font-18">
                    {" "}
                    Survey No.1754, Nr Plasma Induction (INDIA) Pvt Ltd Hajipur Kalol,Dist-Gandhinagar-382721
                  </p>
                  <p className="font-18">
                    You can contact Castomech Technology LLP by
                    email to mkt@castomech.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer4 />
      <ScrollToTop className="custom-scroll-button" offset={500} />
    </>
  );
};

export default privacypolicy;
