import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import ClientSlider1 from "../element/clientSlider1";
import { homeSliderContent8, projectContent1 } from "../element/SliderContent";
import HomeSlider from "../element/homeslider1";
import "../../css/plugins.css";
import "../../css/style.css";
import "../../css/skin/skin-1.css";
import "../../css/templete.min.css";
import ScrollToTop from "../element/scrollToTop";
import CounterSection2 from "../element/counterSection2";
import Footer4 from "../layout/footer4";
import ProjectSlider1 from "../element/projectSlider1";
const bg = require("../../images/background/bg2.jpg");
const bg2 = require("../../images/background/bg4.jpg");
const bg3 = require("../../images/background/map-bg.png");
const bg4 = require("../..//images/CastomechShed.jpeg");

class Index extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
}

  render() {
    
    return (
      <div className="skin-1">
        <Header />

        <HomeSlider
          data={homeSliderContent8}
          contentWrapperCls="sliderStyle3"
          hideBtn
        />

        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-gray content-inner about-carousel-ser">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="title">
                    Why Customer Love To <br />
                    <span style={{ color: "red" }}>Castomech Technology?</span>
                  </h2>
                  <p>
                    Castomech Technology Given Complete Solution Of Sand
                    Reclamation Equipment For Foundry Industry.
                  </p>
                </div>
              </div>
              <div className="content-block">
                <div className="section-full content-inner bg-white video-section">
                  <div className="container">
                    <div className="section-content">
                      <div className="row d-flex">
                        <div className="col-lg-6 col-md-12 m-b30">
                          <div className="video-bx">
                            <img
                              src={require("../../images/MainBg.webp")}
                              alt="Signature"
                            />
                            <div className="video-play-icon"></div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                          <div className="content-bx1">
                            <h2 className="m-b15 title">
                              CASTOMECH TECHNOLOGY
                              <br />
                              <span className="text-primary">
                                {" "}
                                We Cast Your Goal
                              </span>
                            </h2>
                            <p className="m-b30">
                              Castomech Technology LLP is the group company of
                              Plasma Group. Castom Technology is leading
                              manufacturer of sand Reclamation Plant (Various
                              Proc Equipment). Castomech Technology is one of
                              the large producer of high quality, lasting
                              foundry equipment with good productivity. We
                              manufacture Green Process, Lost Foam Sand Process
                              and Noback-Co2 Sand Process plant.
                            </p>
                            <Link to={"/about"} className="site-button">
                        Read More
                      </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="section-full content-inner-2 bg-img-fix overlay-black-dark wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.8s"
                style={{ backgroundImage: "url(" + bg + ")" }}
              >
                <div className="container">
                  <div className="section-head text-white text-center">
                    <h3 className="title">Our Products</h3>
                    <h4>Efficient Furnaces for Precision Heating & Melting</h4>
                  </div>
                  <div className="row icon-white">
                    <div className="col-lg-12 col-md-12">
                      <ProjectSlider1 data={projectContent1} />
                    </div>
                  </div>
                </div>
              </div>
              {/* OurProduct */}
              {/* <div className="section-full bg-white content-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="sort-title clearfix text-center">
                        <h4>Our Product</h4>
                      </div>
                      <div className="section-content p-b0">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                            <div className="dlab-box img-content-style-1 fly-box">
                              <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                <img
                                  src={require("../../images/product/IntensiveMixer.png")}
                                  alt=""
                                />
                              </div>
                              <div className="dlab-title-bx bg-white p-a20 text-center">
                                <p className="font-16 text-secondry m-b5">
                                  Intensive Mixer
                                </p>
                                <div className="dlab-divider margin-0 bg-black "></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                            <div className="dlab-box img-content-style-1 fly-box">
                              <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                <img
                                  src={require("../../images/product/PolygonalSiever.png")}
                                  alt=""
                                />
                              </div>
                              <div className="dlab-title-bx bg-white p-a20 text-center">
                                <p className="font-16 text-secondry m-b5">
                                  Polygonal Siever
                                </p>
                                <div className="dlab-divider margin-0 bg-black"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                            <div className="dlab-box img-content-style-1 fly-box">
                              <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                <img
                                  src={require("../../images/product/VibroFeeder.png")}
                                  alt=""
                                />
                              </div>
                              <div className="dlab-title-bx bg-white p-a20 text-center">
                                <p className="font-16 text-secondry m-b5">
                                  Vibro Feeder
                                </p>
                                <div className="dlab-divider margin-0 bg-black"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                            <div className="dlab-box img-content-style-1 fly-box">
                              <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                <img
                                  src={require("../../images/product/MixerSingle.png")}
                                  alt=""
                                />
                              </div>
                              <div className="dlab-title-bx bg-white p-a20 text-center">
                                <p className="font-16 text-secondry m-b5">
                                  Mixer Single
                                </p>
                                <div className="dlab-divider margin-0 bg-black"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                            <div className="dlab-box img-content-style-1 fly-box">
                              <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                <img
                                  src={require("../../images/product/DoubleArm.png")}
                                  alt=""
                                />
                              </div>
                              <div className="dlab-title-bx bg-white p-a20 text-center">
                                <p className="font-16 text-secondry m-b5">
                                  Double Arm
                                </p>
                                <div className="dlab-divider margin-0 bg-black"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                            <div className="dlab-box img-content-style-1 fly-box">
                              <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                <img
                                  src={require("../../images/product/BatchMixer.webp")}
                                  alt=""
                                />
                              </div>
                              <div className="dlab-title-bx bg-white p-a20 text-center">
                                <p className="font-16 text-secondry m-b5">
                                  Batch Mixer
                                </p>
                                <div className="dlab-divider margin-0 bg-black"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
           
            <div className="section-full bg-gray content-inner">
              <div className="container">
                <div className="section-content row">
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.2s"
                  >
                    <div className="icon-bx-wraper" data-name="01">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <img
                            src={require("../../images/QualityStandards.png")}
                            alt="Mechanical Icon"
                            className="icon-img"
                          />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Quality Standards</h2>
                        <p>
                          We diligently adhere to international quality
                          standards in our manufacturing and packaging
                          procedures to ensure that our customers' needs are
                          met. Our...{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.4s"
                  >
                    <div className="icon-bx-wraper" data-name="02">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <img
                            src={require("../../images/ProductionFacility.png")}
                            alt="Mechanical Icon"
                            className="icon-img"
                          />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Production Facility</h2>
                        <p>
                          We are dedicated to presenting our products in the
                          best possible packaging. To do this, we have built a
                          well-organised packaging and warehousing depar...{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.6s"
                  >
                    <div className="icon-bx-wraper" data-name="03">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <img
                            src={require("../../images/OurTeam.png")}
                            alt="Mechanical Icon"
                            className="icon-img"
                          />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Our Team</h2>
                        <p>
                          Our working team is made up of highly qualified,
                          professional, and experienced individuals. These
                          individuals are experts in their fields. We recruit ..{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CounterSection2 backgroundImage={bg2} />
            <div
              className="section-full dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="container">
                <div className="section-content">
                  <ClientSlider1 hideBorder />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer4 hideContactInfo displayNewsLetter />
        <ScrollToTop className="icon-up" />
      </div>
    );
  }
}
export default Index;
