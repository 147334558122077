import React, { Component } from "react";
import { Link } from "react-router-dom";
import PDFJSON from "../JasonData/PDFURL.json";
import Header from "../layout/header";
import Footer4 from "../layout/footer4";
import ClientSlider1 from "../element/clientSlider1";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import ScrollToTop from "../element/scrollToTop";
import jsonData from "../JasonData/ContinuousMixer.json";
import jsonData2 from "../JasonData/ContinuousMixercO2.json";
const proimage = require("../../images/product/ContinuousMixer/ContinuousMixer1.webp");
const proimage1 = require("../../images/product/ContinuousMixer/ContinuousMixer2.webp");
var bnr3 = require("./../../images/banner/bnr3.webp");

class ContinuousMixer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      name: "",
      email: "",
      mobile: "",
      showSuccessMessage: false,
      alertMessage: "", // New state for alert message content
      alertType: "", // New state for alert type (success or error)
      captchaToken: "", // New state for captcha token
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false, alertMessage: "", alertType: "" });
  };
  // Handle captcha change
  handleRecaptchaChange = (token) => {
    this.setState({ captchaToken: token });
  };

  handleDownload = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + PDFJSON.INTMIXERURL;
    link.download = "Intensivemixer.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { name, email, mobile } = this.state;
    const templateParams = { name, email, mobile };

    emailjs
      .send(
        "service_28emj8y",
        "template_2rotnxq",
        templateParams,
        "9RWNT68fRn3NLxh45"
      )
      .then(() => {
        this.setState({
          showSuccessMessage: true,
          alertMessage: "Details submitted successfully! Thank you.",
          alertType: "success",
        });
        this.handleDownload();
        setTimeout(() => this.closePopup(), 3000); // Optional: Close popup after 3 seconds
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        this.setState({
          alertMessage: "Failed to submit details. Please try again.",
          alertType: "error",
        });
      });
  };

  render() {
    const {
      isPopupOpen,
      name,
      email,
      mobile,
      alertMessage,
      alertType,
      captchaToken,
    } = this.state;
    return (
      <>
        <Header />
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bnr3 + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Continuous Mixer</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>Continuous Mixer</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="section-full content-inner bg-white video-section">
                <div className="container">
                  <div className="section-content">
                    <div className="row d-flex">
                      <div className="col-md-6" style={{ textAlign: "center" }}>
                        <div className="row">
                          <img
                            className="img-fluid"
                            src={proimage}
                            alt="image"
                          />
                        </div>
                        <div className="blog-post blog-lg blog-rounded ">
                          <button
                            className="site-button black m-r5"
                            type="button"
                            onClick={this.openPopup}
                          >
                            <span className="site-button-inr">
                              Catalogue <i className="ti-download"></i>
                            </span>
                          </button>
                        </div>
                        <div></div>
                      </div>
                      <div className="col-lg-6 col-md-12 m-b30 ">
                        <div className="content-bx1">
                          <label
                            className="gradiant-label"
                            style={{ color: "white", fontSize: "x-large" }}
                          >
                            Continuous{" "}
                            <span style={{ color: "#ee4c21" }}>Mixer</span>
                          </label>

                          <p className="m-b30">
                            <i
                              className="fa fa-gear "
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            ></i>
                            Continuous Mixer is designed for thorough blending
                            and mixing of sand with sodium silicate or No-Bake
                            binders. This is an ideal machine for applications
                            requiring small, medium or large moulds or cores,
                            and is offered in four sizes with capacities ranging
                            from 1.5 to 50 tonnes per hour.
                          </p>

                          <p className="m-b30">
                            <i
                              className="fa fa-gear "
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            ></i>
                            The mixing blades are hard faced with tungsten
                            carbide bits. Sand entering the mixing tube is
                            metered by an easily adjustable pneumatically
                            operated gate. The mixing tube opens into two halves
                            for full access to mixing blades for cleaning. The
                            shaft and tube assemblies can also be easily removed
                            for cleaning and maintenance. pneumatically operated
                            gate.
                          </p>
                          <p className="m-b0">
                            <i
                              className="fa fa-gear "
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            ></i>
                            The mixing tube opens into two halves for full
                            access to mixing blades for cleaning. The shaft and
                            tube assemblies can also be easily removed for
                            cleaning and maintenance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md6 col-lg-4 col-xl-6">
                  <label
                    className="gradiant-label"
                    style={{ color: "white", fontSize: "x-large" }}
                  >
                    Continuous Mixer{" "}
                    <span style={{ color: "#ee4c21" }}>SPECIFICATION </span>
                  </label>

                  <div
                    className="card-body"
                    style={{
                      background: "#f4f4f4",
                      padding: "15px 10px",
                      marginBottom: "10px",
                      border: "5px solid #fff",
                      boxShadow: "0 0 7px 1px rgba(0,0,0,.26)",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                        <span style={{ color: "#ee4c21" }}></span>05 to 20 M.T.
                        per Hour for "No Bake" Mould sand.{" "}
                      </span>
                    </div>

                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                        <span style={{ color: "#ee4c21" }}></span>• 03 to 20
                        M.T. per Hour for CO system Mould sand.{" "}
                      </span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                        <span style={{ color: "#ee4c21" }}></span>• Covers a
                        span of Radial 06 feet in 270° of moulding area.{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="video-bx">
                    <div className="row">
                      <img className="img-fluid" src={proimage1} alt="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content" style={{ background: "#f4f4f4" }}>
            {/* TABLE1 */}
            <div className="content-block">
              <div className="section-full content-inner video-section">
                <div className="container">
                  <div className="section-content">
                    <div className="row d-flex">
                      <div className="col-lg-6 col-md-12 m-b30 "></div>
                      <label
                        className="gradiant-label"
                        style={{ color: "white", fontSize: "x-large" }}
                      >
                        TECHNICAL SPECIFICATION FOR{" "}
                        <span style={{ color: "#ee4c21" }}>
                          {" "}
                          NO-BAKE PROCESS{" "}
                        </span>
                      </label>
                      <div className="col-lg-12 col-md-12 m-b30 align-self-center video-infobx">
                        {/* Table Wrapper for Horizontal Scrolling */}
                        <div style={{ overflowX: "auto" }}>
                          <table style={{ width: "100%", minWidth: "600px" }}>
                            <thead>
                              <tr>
                                {/* <th style={{ color: "white" }}>SR.NO.</th> */}
                                <th style={{ color: "white" }}>NO.</th>
                                <th style={{ color: "white" }}>MODEL NO.</th>
                                <th style={{ color: "white" }}>CAPACITY</th>
                                <th style={{ color: "white" }}>Mixer Motor</th>
                                <th style={{ color: "white" }}>
                                  Hardener Motor
                                </th>
                                <th style={{ color: "white" }}>Resin Motor</th>
                              </tr>
                            </thead>
                            <tbody>
                              {jsonData.map((data) => (
                                <tr key={data.id}>
                                  <td>{data.title}</td>
                                  <td>{data.MODELNO}</td>
                                  <td>{data.CAPACITY}</td>
                                  <td>{data.MixerMotor}</td>
                                  <td>{data.HardenerMotor}</td>
                                  <td>{data.ResinMotor}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TABLE2 */}
            <div className="content-block">
              <div className="section-full content-inner video-section">
                <div className="container">
                  <div className="section-content">
                    <div className="row d-flex">
                      <div className="col-lg-6 col-md-12 m-b30 "></div>
                      <label
                        className="gradiant-label"
                        style={{ color: "white", fontSize: "x-large" }}
                      >
                        TECHNICAL SPECIFICATION FOR{" "}
                        <span style={{ color: "#ee4c21" }}> CO2 PROCESS </span>
                      </label>
                      <div className="col-lg-12 col-md-12 m-b30 align-self-center video-infobx">
                        {/* Table Wrapper for Horizontal Scrolling */}
                        <div style={{ overflowX: "auto" }}>
                          <table style={{ width: "100%", minWidth: "600px" }}>
                            <thead>
                              <tr>
                                <th style={{ color: "white" }}>NO.</th>
                                <th style={{ color: "white" }}>MODEL NO.</th>
                                <th style={{ color: "white" }}>CAPACITY</th>
                                <th style={{ color: "white" }}>Mixer Motor</th>
                                <th style={{ color: "white" }}>
                                  Silicate Motor
                                </th>
                                <th style={{ color: "white" }}>
                                Water Motor
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {jsonData2.map((data) => (
                                <tr key={data.id}>
                                  <td>{data.title}</td>
                                  <td>{data.MODELNO}</td>
                                  <td>{data.CAPACITY}</td>
                                  <td>{data.MixerMotor}</td>
                                  <td>{data.SilicateMotor}</td>
                                  <td>{data.WaterMotor}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <ClientSlider1 hideBorder />
            </div>
          </div>
        </div>
        {/* Popup Modal */}
        {alertMessage && (
          <div className={`alert alert-${alertType}`}>
            <strong>{alertType === "success" ? "Success!" : "Error!"}</strong>{" "}
            {alertMessage}
          </div>
        )}

        {/* Popup Modal */}
        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-button" onClick={this.closePopup}>
                &times;
              </button>
              {alertType === "success" ? (
                <div className="success-message">{alertMessage}</div>
              ) : (
                <>
                  <h4 className="popup-title">Download Catalogue</h4>
                  <p className="popup-subtitle">
                    Enter your details to download
                  </p>
                  <form onSubmit={this.handleFormSubmit}>
                    <div className="popup-form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="popup-form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="popup-form-group">
                      <label>Mobile</label>
                      <input
                        type="tel"
                        name="mobile"
                        value={mobile}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="recaptcha-wrapper">
                              <ReCAPTCHA
                                sitekey="6LfJfAgpAAAAAKxWLLAbkYw9Vx1U-WN9fPi66Nsu"
                                onChange={this.handleRecaptchaChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className={`site-button button-md ${
                        !captchaToken ? "disabled-button" : ""
                      }`}
                      disabled={!captchaToken} // Disable button if captchaToken is empty
                    >
                      <span>Send</span>
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        )}
        <Footer4 />
<ScrollToTop className="icon-up" />
        <ScrollToTop className="icon-up" />
      </>
    );
  }
}

export default ContinuousMixer;
