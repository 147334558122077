import React, { Component } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import Header from "../layout/header";
import Footer4 from "../layout/footer4";
import ReCAPTCHA from "react-google-recaptcha";
import ScrollToTop from "../element/scrollToTop";

const bg = require("../../images/banner/bnr3.webp");

class Contact2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: "",
      alertMessage: "",
      alertType: "",
      captchaToken: "", // New state for captcha token
    };
  }

  // Handle input change
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // Handle captcha change
  handleRecaptchaChange = (token) => {
    this.setState({ captchaToken: token });
  };

  // Function to send the email
  sendEmail = (e) => {
    e.preventDefault();

    if (!this.state.captchaToken) {
      this.setState({
        alertMessage: "Please complete the captcha verification.",
        alertType: "danger",
      });
      return;
    }

    const serviceID = "service_28emj8y";
    const templateID = "template_2rotnxq";
    const userID = "9RWNT68fRn3NLxh45";

    emailjs.sendForm(serviceID, templateID, e.target, userID).then(
      (result) => {
        console.log(result.text);
        this.setState({
          alertMessage: "Email sent successfully!",
          alertType: "success",
          name: "",
          phone: "",
          email: "",
          message: "",
          captchaToken: "", // Reset captcha token after successful submission
        });
      },
      (error) => {
        console.log(error.text);
        this.setState({
          alertMessage: "Failed to send email. Please try again.",
          alertType: "danger",
        });
      }
    );
  };

  render() {
    const {
      name,
      phone,
      email,
      message,
      alertMessage,
      alertType,
      captchaToken,
    } = this.state;

    return (
      <>
        <Header />
        <div className="page-content bg-white">
          {/* Banner Section */}
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{ backgroundImage: "url(" + bg + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Contact Us</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to="index">Home</Link>
                    </li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-full content-inner contact-page-8 bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-12 col-sm-6 ">
                      <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-location-pin"></i>
                            </span>
                            Company Address
                          </h5>
                          <p>
                            Survey No.1754, Nr Plasma Induction (INDIA) Pvt Ltd
                            Hajipur Kalol,Dist-Gandhinagar-382721{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 ">
                      <div className="icon-bx-wraper expertise bx-style-1 p-a20 m-b20 radius-sm">
                        <div className="icon-content m-b60 ">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-email"></i>
                            </span>
                            E-mail
                          </h5>

                          <a href="mailto:mkt@castomech.com">
                            mkt@castomech.com
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 ">
                      <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                        <div className="icon-content m-b40">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-mobile"></i>
                            </span>
                            Phone Numbers
                          </h5>
                          <a href="tel:+919054005999">+91 90540 05999</a>
                          <br />
                          <a href="tel:+919033304845">+91 90333 04845</a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 ">
                      <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <div className="row section-content box-sort-in  button-example">
                            <h5 className="dlab-tilte text-uppercase">
                              <span className="icon-sm text-primary">
                                <i className="ti-desktop"></i>
                              </span>
                              Social
                            </h5>
                            <div
                              className="col-md-"
                              style={{ width: "40px", height: "30px" }}
                            >
                              <a
                                href="https://www.facebook.com/CASTOMECH?mibextid=ZbWKwL"
                                target="_blank"
                                className="site-button facebook m-r15 sharp-sm outline"
                                style={{ width: "30px", height: "30px" }}
                              >
                                <i className="fa fa-facebook"></i>
                              </a>
                            </div>

                            <div
                              className="col-md-3 mx-1"
                              style={{ width: "35px", height: "30px" }}
                            >
                              <a
                                href="https://www.linkedin.com/company/castomech-technology/"
                                target="_blank"
                                className="site-button linkedin m-r15 sharp-sm outline"
                                style={{ width: "30px", height: "30px" }}
                              >
                                <i className="fa fa-linkedin"></i>
                              </a>
                            </div>
                            <div
                              className="col-md-3 mx-2"
                              style={{ width: "35px", height: "30px" }}
                            >
                              <a
                                href="https://www.instagram.com/castomechtechnology?igsh=MTQ5OWN2NXd2bWdjYw=="
                                target="_blank"
                                className="site-button instagram m-r15 sharp-sm outline"
                                style={{ width: "30px", height: "30px" }}
                              >
                                <i className="fa fa-instagram"></i>
                              </a>
                            </div>

                            <div
                              className="col-md-3 mx-2"
                              style={{ width: "35px", height: "40px" }}
                            ></div>
                          </div>
                          <div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Other contact info */}
                </div>
                {/* Contact Form */}
                <div className="col-lg-8 col-md-12 m-b30">
                  <form
                    className="inquiry-form wow fadeInUp"
                    onSubmit={this.sendEmail}
                  >
                    <h3 className="title-box font-weight-300 m-t0 m-b10">
                      Let's Connect
                      <span className="bg-primary"></span>
                    </h3>

                    {/* Alert Messages */}
                    {alertMessage && (
                      <div className={`alert alert-${alertType}`}>
                        <strong>
                          {alertType === "success" ? "Success!" : "Error!"}
                        </strong>{" "}
                        {alertMessage}
                      </div>
                    )}

                    {/* Form Fields */}
                    <div className="row">
                      {/* Name */}
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-user text-primary"></i>
                            </span>
                            <input
                              name="name"
                              type="text"
                              value={name}
                              onChange={this.handleChange}
                              required
                              className="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Phone */}
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-mobile text-primary"></i>
                            </span>
                            <input
                              name="phone"
                              type="text"
                              value={phone}
                              onChange={this.handleChange}
                              required
                              className="form-control"
                              placeholder="Phone"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Email */}
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-email text-primary"></i>
                            </span>
                            <input
                              name="email"
                              type="email"
                              value={email}
                              onChange={this.handleChange}
                              required
                              className="form-control"
                              placeholder="Your Email Id"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Message */}
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-agenda text-primary"></i>
                            </span>
                            <textarea
                              name="message"
                              rows="4"
                              value={message}
                              onChange={this.handleChange}
                              className="form-control"
                              required
                              placeholder="Tell us about your project or idea"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      {/* reCAPTCHA */}
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="recaptcha-wrapper">
                              <ReCAPTCHA
                                sitekey="6LfJfAgpAAAAAKxWLLAbkYw9Vx1U-WN9fPi66Nsu"
                                onChange={this.handleRecaptchaChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Submit Button */}
                      <div className="col-lg-12 col-md-12">
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className={`site-button button-md ${
                            !captchaToken ? "disabled-button" : ""
                          }`}
                          disabled={!captchaToken} // Disable button if captchaToken is empty
                        >
                          <span>Send</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                {/* Google Map */}
                <div className="col-lg-4 col-md-12 m-b30">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31565.68585730236!2d72.4189373!3d23.1785038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c20d49b7a6901%3A0xe900daf9a6903467!2sPlasma%20Induction%20(India)%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1619103200990!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer4 />
        <ScrollToTop className="icon-up" />
      </>
    );
  }
}

export default Contact2;
