import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Footer4 from "../layout/footer4";
import ScrollToTop from "../element/scrollToTop";

var bnr3 = require("./../../images/banner/bnr3.webp");

class About2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{ backgroundImage: "url(" + bnr3 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">About us</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>About us 2</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="section-full content-inner bg-white">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12 m-b30">
                    <div className="our-story">
                      <span>OUR STORY</span>
                      <h2 className="title">
                        Running a <br />
                        successful business <br />
                        <span className="text-primary">since 2018</span>
                      </h2>
                      <h4 className="title">
                        All of our items are manufactured in a high-tech
                        production facility by a team of skilled technicians,
                        engineers, and quality controllers.
                      </h4>
                      <p>
                        We started manufacturing within a short span of years;
                        Establishment we have supplied more than 150+ sand
                        reclamation plant & machinery & mold handling Equipment
                        as well as Genuine Spares. Castomech Technology has
                        grown into one of the world's most recognized and famous
                        names in foundry equipment in India.
                      </p>
                      
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                    <img
                      src={require("../../images/about/pic6.webp")}
                      className="radius-sm"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full box-about-list">
              <div className="row spno">
                <div className="col-lg-6 col-md-12">
                  <img
                    src={require("../../images/about/pic33.webp")}
                    alt=""
                    className="img-cover"
                  />
                </div>
                <div className="col-lg-6 col-md-12 bg-primary">
                  <div className="max-w700 m-auto p-tb50 p-lr20">
                    <div className="text-white">
                      <h2>
                        We're thriving and building <br />
                        better products
                      </h2>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to={"/#"} className="icon-cell text-white">
                          <img
                            src={require("../../images/TechnicalTeam.png")}
                            alt="Mechanical Icon"
                            className="icon-img"
                          />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h4 className="dlab-tilte">
                          Best Research & Development
                        </h4>
                        <p>
                          Leading the way in innovation, our Research &
                          Development team is dedicated to advancing product
                          quality and sustainability, ensuring we bring the best
                          solutions to market.
                        </p>
                      </div>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <img
                          src={require("../../images/BestResearch.png")}
                          alt="Mechanical Icon"
                          className="icon-img"
                        />
                      </div>
                      <div className="icon-content">
                        <h4 className="dlab-tilte">
                          Most Experience Technical Team
                        </h4>
                        <p>
                          Our highly experienced technical team brings
                          unparalleled expertise, driving innovation and
                          ensuring top-notch solutions across all our projects.
                        </p>
                      </div>
                    </div>
                    <div className="icon-bx-wraper left">
                      <div className="icon-md">
                        <Link to={"/#"} className="icon-cell text-white">
                          <img
                            src={require("../../images/BestQulity.png")}
                            alt="Mechanical Icon"
                            className="icon-img"
                          />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h4 className="dlab-tilte">Best Quality Product</h4>
                        <p>
                          We are committed to delivering the best quality
                          products, crafted with precision and care to meet the
                          highest industry standards.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full about-progress">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12 bg-white">
                    <div className="max-w900  m-auto content-inner">
                      <div className="section-head">
                        <h2 className="title">
                          Our Experience In Sand Reclamation
                        </h2>
                      </div>
                      <div className="about-progress-box">
                        <h6 className="text-primary">
                          Green Sand Reclamation System{" "}
                          <span className="progress-num pull-right">85%</span>
                        </h6>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.2s"
                            data-wow-duration="2s"
                            style={{ width: "85%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="about-progress-box">
                        <h6 className="text-primary">
                          No-Bake Sand Reclamation System
                          <span className="progress-num pull-right">89%</span>
                        </h6>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.4s"
                            data-wow-duration="2s"
                            style={{ width: "85%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer4 />
<ScrollToTop className="icon-up" />
        <ScrollToTop className="icon-up" />
      </>
    );
  }
}
export default About2;
