import React, { Component } from 'react';
import { animateScroll } from 'react-scroll';

class ScrollToTop extends Component {
  state = {
    offset: this.props.offset || 1000,
    show: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { offset } = this.state;
    this.setState({ show: window.pageYOffset > offset });
  };

  scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  render() {
    return (
      <>
        {this.state.show && (
          <button
            className={`scroltop ${this.props.className}`}
            type="button"
            onClick={this.scrollToTop}
            style={{ display: "block" }}
          >
            <i className="fa fa-arrow-up"></i>
          </button>
        )}
      </>
    );
  }
}

// Optionally set default props
ScrollToTop.defaultProps = {
  offset: 1000,
};

export default ScrollToTop;
