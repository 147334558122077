import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from './pages/index';
import About2 from './pages/about-2';
import Contact2 from './pages/contact-2';
import Intensivemixer from './Component/Intensivemixer';
import PolygonalSiever from './Component/PolygonalSiever';
import KnockoutMachine from './Component/KnockoutMachine';
import VibroFeeder from './Component/VibroFeeder';
import Overbandmagneticspaerator from './Component/Overbandmagneticspaerator';
import SandCooler from './Component/SandCooler';
import ContinuousMixer from './Component/ContinuousMixer';
import Compactiontables from './Component/Compactiontables';
import PneumaticConveyorSystem from './Component/PneumaticConveyorSystem';
import BatchMixerMachine from './Component/BatchMixerMachine';
import Lunmreducer from './Component/Lunmreducer';
import RollOver from './Component/RollOver';
import DoubleArm from './Component/DoubleArm';
import privacypolicy from './layout/privacypolicy';


class Router extends Component {
    render() {
        return (
            
			//<BrowserRouter>
			<BrowserRouter basename={'/'}>
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Index} />
                        <Route path='/about' component={About2} />
                        <Route path='/contact' component={Contact2} />
                        <Route path='/intensivemixer' component={Intensivemixer} />
                        <Route path='/polygonalSiever' component={PolygonalSiever} />
                        <Route path='/knockoutMachine' component={KnockoutMachine} />
                        <Route path='/vibroFeeder' component={VibroFeeder} />
                        <Route path='/overbandmagneticspaerator' component={Overbandmagneticspaerator} />
                        <Route path='/sandcooler' component={SandCooler} />
                        <Route path='/continuousmixer' component={ContinuousMixer} />
                        <Route path='/compactiontables' component={Compactiontables} />
                        <Route path='/pneumaticconveyorsystem' component={PneumaticConveyorSystem} />
                        <Route path='/batchmixermachine' component={BatchMixerMachine} />
                        <Route path='/Lumpreducer' component={Lunmreducer} />
                        <Route path='/rollover' component={RollOver} />
                        <Route path='/doublearmcontmixer' component={DoubleArm} />
                        <Route path='/privacy-policy' component={privacypolicy} />

                    </Switch>
                </div>

            </BrowserRouter>

        )
    }
}

export default Router;