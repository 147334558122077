import React, { Component } from "react";
import { Link } from "react-router-dom";
import PDFJSON from "../JasonData/PDFURL.json";
import Header from "../layout/header";
import Footer4 from "../layout/footer4";
import ClientSlider1 from "../element/clientSlider1";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import ScrollToTop from "../element/scrollToTop";
import jsonData from "../JasonData/RollOver.json";
const proimage = require("../../images/product/RollOver.webp");
const proimage1 = require("../../images/product/RollOver.webp");
var bnr3 = require("./../../images/banner/bnr3.webp");

class RollOver extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      name: "",
      email: "",
      mobile: "",
      showSuccessMessage: false,
      alertMessage: "", // New state for alert message content
      alertType: "", // New state for alert type (success or error)
      captchaToken: "", // New state for captcha token
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false, alertMessage: "", alertType: "" });
  };
  // Handle captcha change
  handleRecaptchaChange = (token) => {
    this.setState({ captchaToken: token });
  };

  handleDownload = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + PDFJSON.INTMIXERURL;
    link.download = "Intensivemixer.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { name, email, mobile } = this.state;
    const templateParams = { name, email, mobile };

    emailjs
      .send(
        "service_28emj8y",
        "template_2rotnxq",
        templateParams,
        "9RWNT68fRn3NLxh45"
      )
      .then(() => {
        this.setState({
          showSuccessMessage: true,
          alertMessage: "Details submitted successfully! Thank you.",
          alertType: "success",
        });
        this.handleDownload();
        setTimeout(() => this.closePopup(), 3000); // Optional: Close popup after 3 seconds
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        this.setState({
          alertMessage: "Failed to submit details. Please try again.",
          alertType: "error",
        });
      });
  };

  render() {
    const {
      isPopupOpen,
      name,
      email,
      mobile,
      alertMessage,
      alertType,
      captchaToken,
    } = this.state;
    return (
      <>
        <Header />
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bnr3 + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Roll Over</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>Roll Over</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="section-full content-inner bg-white video-section">
                <div className="container">
                  <div className="section-content">
                    <div className="row d-flex">
                      <div className="col-md-6" style={{ textAlign: "center" }}>
                        <div className="row">
                          <img
                            className="img-fluid"
                            src={proimage}
                            alt="image"
                          />
                        </div>
                        <div className="blog-post blog-lg blog-rounded ">
                          <button
                            className="site-button black m-r5"
                            type="button"
                            onClick={this.openPopup}
                          >
                            <span className="site-button-inr">
                              Catalogue <i className="ti-download"></i>
                            </span>
                          </button>
                        </div>
                        <div></div>
                      </div>
                      <div className="col-lg-6 col-md-12 m-b0 ">
                        <div className="content-bx1">
                          <label
                            className="gradiant-label"
                            style={{ color: "white", fontSize: "x-large" }}
                          >
                            Roll <span style={{ color: "#ee4c21" }}>Over </span>
                          </label>
                          <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Speciality Rollover options available for moulds
                              up to 4000mm in length.
                            </p>
                          </div>
                          <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              C-type designs for a 90-degree ejection, ideal as
                              a space saving solution.{" "}
                            </p>
                          </div>

                          <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Extensive sizes and configurations for integration
                              into existing workflows.{" "}
                            </p>
                          </div>

                          <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Robust and durable construction for low
                              maintenance requirements.{" "}
                            </p>
                          </div>

                          <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Hydraulic driven for smooth and precise Rotation
                              and encoder positioning.{" "}
                            </p>
                          </div>

                          <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Variable Width Clamping– Dual opposing hydraulic
                              cylinders fitted with clamping jaws matching the
                              batten board configuration of your patterns are
                              used to clamp the pattern.{" "}
                            </p>
                          </div>
                          {/* <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Fully Automatic Operation– The automated upgrade
                              performs the Task with a pre-programmed rolling
                              and stripping cycle designed to eliminate a full
                              time operator.{" "}
                            </p>
                          </div>

                          <div>
                            <p
                              className="m-b0"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-gear "
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Draw-Side Belt Conveyor– This option is employed
                              when the use of a bottom Board is not desired.
                              Super-duty belting is used for long life, and ease
                              of replacement is foremost in design.{" "}
                            </p>
                          </div> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md6 col-lg-4 col-xl-6">
                  <label
                    className="gradiant-label"
                    style={{ color: "white", fontSize: "x-large" }}
                  >
                    RollOver <span style={{ color: "#ee4c21" }}>Specication</span>
                  </label>

                  <div
                    className="card-body"
                    style={{
                      background: "#f4f4f4",
                      padding: "15px 10px",
                      marginBottom: "10px",
                      border: "5px solid #fff",
                      boxShadow: "0 0 7px 1px rgba(0,0,0,.26)",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                      Hydraulic working.{" "}
                      </span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                      	Automatic control of operations.{" "}
                      </span>
                    </div>

                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                      Stripping control by “four guide columns”.{" "}
                      </span>
                    </div>

                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                      Automatic cycle – control system by sensors and PLC.{" "}
                      </span>
                    </div>

                    <div>
                      <i className="fa fa-gear mx-2 my-2"></i>
                      <span className="card-text">
                      Control panel independent and interfaced with PLC.{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="video-bx">
                    <div className="row">
                      <img className="img-fluid" src={proimage1} alt="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content" style={{ background: "#f4f4f4" }}>
            <div className="content-block">
              <div className="section-full content-inner video-section">
                <div className="container">
                  <div className="section-content">
                    <div className="row d-flex">
                      <div className="col-lg-6 col-md-12 m-b30 "></div>
                      <div className="col-lg-12 col-md-12 m-b30 align-self-center video-infobx">
                        {/* Table Wrapper for Horizontal Scrolling */}
                        <div style={{ overflowX: "auto" }}>
                          <table style={{ width: "100%", minWidth: "600px" }}>
                            <thead>
                              <tr>
                                <th style={{ color: "white" }}>
                                Model No
                                </th>

                                <th style={{ color: "white" }}> Nominal Mold Size</th>
                                <th style={{ color: "white" }}>Standard Configuration</th>
                              </tr>
                            </thead>
                            <tbody>
                              {jsonData.map((data) => (
                                <tr key={data.id}>
                                  <td>{data.title}</td>
                                  <td>{data.Size}</td>
                                  <td>{data.Configuration}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-content">
              <ClientSlider1 hideBorder />
            </div>
          </div>
        </div>
        {/* Popup Modal */}
        {alertMessage && (
          <div className={`alert alert-${alertType}`}>
            <strong>{alertType === "success" ? "Success!" : "Error!"}</strong>{" "}
            {alertMessage}
          </div>
        )}

        {/* Popup Modal */}
        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-button" onClick={this.closePopup}>
                &times;
              </button>
              {alertType === "success" ? (
                <div className="success-message">{alertMessage}</div>
              ) : (
                <>
                  <h4 className="popup-title">Download Catalogue</h4>
                  <p className="popup-subtitle">
                    Enter your details to download
                  </p>
                  <form onSubmit={this.handleFormSubmit}>
                    <div className="popup-form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="popup-form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="popup-form-group">
                      <label>Mobile</label>
                      <input
                        type="tel"
                        name="mobile"
                        value={mobile}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="recaptcha-wrapper">
                              <ReCAPTCHA
                                sitekey="6LfJfAgpAAAAAKxWLLAbkYw9Vx1U-WN9fPi66Nsu"
                                onChange={this.handleRecaptchaChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className={`site-button button-md ${
                        !captchaToken ? "disabled-button" : ""
                      }`}
                      disabled={!captchaToken} // Disable button if captchaToken is empty
                    >
                      <span>Send</span>
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        )}
        <Footer4 />
<ScrollToTop className="icon-up" />
        <ScrollToTop className="icon-up" />
      </>
    );
  }
}

export default RollOver;
